import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl, 
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockClosed from "../images/padlock-closed.svg"
import axios from "axios"
import {
  AUTH_TOKEN_KEY,
  PARAM_CARD_CORECT_NOT_FOUND,
  PARAM_CARD_NMBRE, PARAM_FIRST_NAME,
  PARAM_NOT_YET,
  translate,
  URL_LOGIN
} from "../utils"

class CompanyExist extends Component {
  state = {
    urlRedirectionParams: `?${PARAM_CARD_NMBRE}=none`,
    firstName: null,
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    if (query.get(PARAM_CARD_NMBRE) !== null) {
      const url = `?${PARAM_CARD_NMBRE}=${query.get(PARAM_CARD_NMBRE)}`
      this.setState({ urlRedirectionParams: url })
      if (query.get(PARAM_CARD_CORECT_NOT_FOUND) !== null) {
        const url = `?${PARAM_CARD_NMBRE}=${query.get(PARAM_CARD_NMBRE)}&${PARAM_CARD_CORECT_NOT_FOUND}=${query.get(PARAM_CARD_CORECT_NOT_FOUND)}`
        this.setState({ urlRedirectionParams: url })
      }
    }

    if (query.get(PARAM_FIRST_NAME) !== null) this.setState({firstName: query.get(PARAM_FIRST_NAME)})
  }

  render() {
    const {firstName} = this.state;

    return (
      <Layout withBg>
        <div className="formWrapper">
          <Container>
            <Row className="justify-content-center">
              <div className={'company-exist__contain'}>
              <h1 class="h1-signupin-yellow text-center">{translate("On y est presque !")}</h1>
              <p className="text-center pb-3">{translate('Avez-vous déjà créé votre société')+' ?'}</p>
                <div style={{display: 'flex',width: '100%',justifyContent:'center'}}>
                  <a href={`/user-check-info-two${this.state.urlRedirectionParams}`}>
                    <Button
                      style={{margin: '0px 10px',padding:' 5px 30px'}}
                      className="form-btn button-yellow"
                      variant="success"
                      //type="submit"
                    >
                      {translate('ja')}
                    </Button>
                  </a>
                  <a href={`/user-check-info-two${this.state.urlRedirectionParams}&${PARAM_NOT_YET}=true`}>
                    <Button
                      style={{margin: '0px 10px',padding:' 5px 30px'}}
                      className="form-btn button-yellow"
                      variant="primary"
                      //type="submit"
                    >
                      {translate('Non, pas encore')}
                    </Button>
                  </a>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default CompanyExist
